<template>
<div>
    <div
        v-if="isLoggedIn && sessionObj"
    >
        <AccountNav
            in-menu
        ></AccountNav>
    </div>
    <div
        v-else
    >
        <div
            class="d-flex flex-column pa-4 mt-6 gap-2"
        >
            <v-btn
                class="flex-grow-1"
                color="primary"
                to="/sign-in"
                size="x-large"
                flat
            >
                Sign In
            </v-btn>
            <v-btn
                color="accent-darken-1"
                to="/create-account"
                size="x-large"
                flat
            >
                Create Account
            </v-btn>
        </div>
    </div>
</div>
</template>

<script lang="ts" setup>
    import {
        useCustomAuth,
        useAccountNav
    } from '~/composables';
    import AccountNav from '~/components/account/account-nav/AccountNav/AccountNav.vue';

    defineProps({
        inMenu: {
            type: Boolean,
            default: false
        },
        hideLogout: {
            type: Boolean,
            default: false
        }
    });

    const { sessionObj, isLoggedIn } = useCustomAuth();

    const unWatchLoggedIn = watch(
        isLoggedIn,
        (newIsLoggedIn) => {
            if (newIsLoggedIn) {
                useAccountNav().buildMenu();
            }
        },
        {
            immediate: true
        }
    );

    onBeforeUnmount(() => {
        unWatchLoggedIn();
    });
</script>

<style lang="scss" scoped></style>
